import React, { useState } from 'react';
import logo from './logo.png'; // The path to your logo

function App() {
  const [copied, setCopied] = useState(false);
  const contractNumber = "0x000000000000000000000000000";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractNumber).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // A jest to reset the state, as fleeting as humor in a code review
    });
  };


  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-center py-8">
    <div className="logo-container">
        <img src={logo} alt="Empire Logo" className="w-48 mx-auto" />
      <h1 className="text-white font-mono font-bold text-4xl glitch" data-text="CHUD MONEY">
        CHUD MONEY CHUD
      </h1>
      </div>
      <div className="w-2/5 bg-gray-100 p-1 rounded-lg shadow-lg text-center mt-10 mb-8">
        <h2 className="text-black text-2xl font-bold">SECRET SALSA | WEBSITE SOON | LANDING PAGE</h2>
        <p className="text-gray-600 mt-2">Tokenomics</p>
      </div>


      <div className={`w-2/5 bg-gray-100 p-2 rounded-lg shadow-lg text-center cursor-pointer ${copied ? 'animate-bounce' : ''}`} onClick={copyToClipboard}>
        <h2 className="text-black text-2xl font-bold">Smart Contract</h2>
        <p className="text-gray-600 mt-2">Click to copy contract number</p>
        <p className="text-gray-700">{contractNumber}</p>
        {copied && <p className="text-green-500 animate-heartbeat">Copied!</p>}
      </div>
    </div>

  );
}

export default App;